import { DataTableFilterParams, DataTablePFSEvent, DataTableSortMeta } from 'primereact/datatable'
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import dayjs from 'dayjs'
import { FormikProps, useFormik } from 'formik'
import { IncidentExportRequest } from 'src/modules/incidents/application/dto/IncidentExportRequest'
import { IncidentFindRequest } from 'src/modules/incidents/application/dto/IncidentFindRequest'
import { useIncidentExportQuery } from 'src/modules/incidents/application/IncidentExportQueries'
import { IncidentRow } from 'src/modules/incidents/domain/IncidentRow'
import { HttpIncidentRepository } from 'src/modules/incidents/infrastructure/HttpIncidentRepository'
import { formatDate } from 'src/utils/dateUtils'
import { AuthContext, Authority, PagingData, useInternalUser, useLang, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

import { faDownload, faRefresh } from '@fortawesome/free-solid-svg-icons'

import {
	ClientSelectHandle,
	CorporateGroupSelectHandle,
	Table,
	TooltipButton,
	UfinetBox,
	UfinetButton,
	UfinetSectionBox,
} from 'ufinet-web-components'
import { ColumnsHiddenHandler } from './components/ColumnsHiddenHandler'
import { ListIncidentHeader } from './components/ListIncidentHeader'
import { incidentsColsData } from './IncidentColsData'
import { useListDataQueries } from './useListDataQueries'
import { IIncidentsFilter } from 'src/interfaces/clientRepository/IClientRepository'

interface FormikValues extends DataTablePFSEvent {}

const ListIncidentsPage: FC<IIncidentsFilter> = ({ incidentsFilter }) => {
	const translate = useTranslator()
	const authData = useContext(AuthContext)
	const isInternal = useInternalUser()
	const navigate = useNavigate()
	const lang = useLang()
	const roles = authData.userData?.authorities || []
	const corporatePermissions = Authority.getGtiCorporatePermissions(roles)
	const ftthPermissions = Authority.getGtiFtthPermissions(roles)
	const permissions = {
		corporate: corporatePermissions.canRead,
		ftth: ftthPermissions.canRead,
	}

	const [incidents, setIncidents] = useState<IncidentRow[]>([])
	const [loadingIncidents, setLoadingIncidents] = useState(false)
	const [visibleColumns, setVisibleColumns] = useState<{ [key: string]: boolean }>({
		tickerNumber: true,
		administrativeCode: true,
		title: true,
		country: true,
		customer: true,
		projectTypes: true,
		reportTypes: false,
		incidentStatues: true,
		degradationTypes: false,
		affectationTypes: false,
		reportOrigins: false,
		internalClientTicketNumber: true,
		serviceTypes: false,
		createdDate: true,
		solutionTypes: false,
		primaryCause: false,
		secondaryCause: false,
		imputableTo: false,
		serviceRestorationDate: false,
		statusCloseDate: false,
		totalImputableTime: false,
		rfoSentStatuses: false,
		rfoSentDate: false,
		descriptionDiscountTime: false,
		reportMassiveId: true,
	})

	const incidentRepository = useMemo(() => HttpIncidentRepository(authData), [authData])
	const groupSelectRef = useRef<CorporateGroupSelectHandle>(null)
	const clientSelectRef = useRef<ClientSelectHandle>(null)
	const {
		affectationTypes,
		degradationTypes,
		processStatus,
		projectTypes,
		reportOrigins,
		reportTypes,
		rfoSentStatus,
		serviceTypes,
		solutionTypes,
		countries,
	} = useListDataQueries()

	const initialValues: FormikValues = useMemo(
		() => ({
			countryId: {},
			corporateGroupId:
			incidentsFilter.corporateGroupIdsList && authData.userData?.user?.type === 'EXTERNAL' ? incidentsFilter.corporateGroupIdsList : [],
			customersId: authData.userData?.user?.type === 'EXTERNAL' ? incidentsFilter.customerIds : [],
			dateFrom: undefined as unknown as Date,
			dateTo: undefined as unknown as Date,
			first: 0,
			rows: 10,
			sortField: '',
			sortOrder: 1,
			multiSortMeta: [] as DataTableSortMeta[],
			filters: {} as DataTableFilterParams['filters'],
		}),
		[incidentsFilter]
	)

	const differBy90DaysOrMore = (dateFrom: Date, dateTo: Date) => {
		const differenceInMilliseconds = Math.abs(dateTo.getTime() - dateFrom.getTime())
		const millisecondsInOneDay = 24 * 60 * 60 * 1000
		const differenceInDays = differenceInMilliseconds / millisecondsInOneDay
		return differenceInDays <= 90
	}

	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>({} as DataTablePFSEvent)

	const defaultPaging: PagingData = useMemo(
		() => ({
			pageNumber: 0,
			pageSize: 10,
			totalElements: 0,
			totalPages: 1,
		}),
		[]
	)

	const [paging, setPaging] = useState<PagingData>(defaultPaging)

	const validationSchema = Yup.object().shape({
		dateFrom: Yup.date()
			.optional()
			.test('differBy90DaysOrMore', 'Error', function () {
				const { dateFrom, dateTo } = this.parent
				if (dateFrom && dateTo) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date(dateTo))
				} else if (dateFrom) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date())
				} else {
					return true
				}
			}),
		dateTo: Yup.date()
			.optional()
			.test('differBy90DaysOrMore', 'Error', function () {
				const { dateFrom, dateTo } = this.parent
				if (dateFrom && dateTo) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date(dateTo))
				} else if (dateFrom) {
					return differBy90DaysOrMore(new Date(dateFrom), new Date())
				} else {
					return true
				}
			}),
	})

	const findTickets = (event: DataTablePFSEvent) => {
		setLoadingIncidents(true)
		incidentRepository
			.findAll(IncidentFindRequest.fromValues(isInternal, undefined, lang, formik.values, event, permissions))
			.then((data) => {
				setIncidents(data.incidents)
				setPaging({
					pageNumber: data.pageNumber,
					pageSize: data.rowsPerPage,
					totalPages: data.totalPages,
					totalElements: data.totalRows,
				})
				setLoadingIncidents(false)
			})
			.catch(() => {
				toast.error(translate('ERROR.TICKET.FILTER'))
				setLoadingIncidents(false)
			})
	}

	const formik: FormikProps<FormikValues> = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: findTickets,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	useEffect(() => {
		findTickets(tablePFSEvent)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { mutate: exportIncidents, isLoading: isExporting } = useIncidentExportQuery(incidentRepository, {
		onSuccess: (response) => {
			response.blob().then((data) => {
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement('a')
				link.href = url
				link.download = `Incidents_Export_From_${formatDate(formik.values.dateFrom, '-')}_To_${formatDate(formik.values.dateTo, '-')}.xlsx`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				toast.success(translate('TICKET.EXPORT.SUCCESS'))
			})
		},
		onError: () => toast.error(translate('TICKET.EXPORT.ERROR')),
	})

	const handleExportIncidents = () => {
		const { values } = formik
		const dayJsTo = dayjs(values.dateTo)

		if (!values.dateFrom) {
			return toast.error(translate('TICKET.EXPORT.SELECT_DATE_FROM'))
		}
		if (dayJsTo.diff(values.dateFrom, 'days') > 90 && values.dateTo) {
			return toast.error(translate('TICKET.EXPORT.DATE_RANGE'))
		}

		exportIncidents(
			IncidentExportRequest.fromValues(
				isInternal,
				authData.userData?.username,
				lang,
				formik.values,
				tablePFSEvent,
				permissions
			)
		)
	}

	const navigateTo = (row: IncidentRow) => {
		navigate(
			row?.projectType?.includes('FTTH')
				? `/ftth-tickets-detail/${row.incidentId}`
				: `/corporate-tickets-detail/${row.incidentId}`
		)
	}

	return (
		<UfinetBox>
			<form onSubmit={formik.handleSubmit}>
				<ListIncidentHeader
					formik={formik}
					clientSelectRef={clientSelectRef}
					groupSelectRef={groupSelectRef}
					findTickets={findTickets}
					tablePFSEvent={tablePFSEvent}
				/>

				<UfinetSectionBox className="mt-3">
					<Table
						cols={incidentsColsData(
							translate,
							navigateTo,
							visibleColumns,
							reportTypes,
							degradationTypes,
							affectationTypes,
							projectTypes,
							processStatus,
							reportOrigins,							
							rfoSentStatus,
							serviceTypes,
							solutionTypes,
							countries,
						)}
						content={incidents}
						editDisabled={true}
						onFilterClear={() => {
							formik.resetForm()
							findTickets({} as DataTablePFSEvent)
						}}
						headerButtons={
							<div className="d-flex flex-row gap-3">
								<ColumnsHiddenHandler visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
								<UfinetButton
									icon={faRefresh}
									type="button"
									id="refresh-button"
									onClick={() => findTickets(tablePFSEvent)}
								/>
								{(corporatePermissions.canExport || ftthPermissions.canExport) &&
									(isExporting ? (
										<TooltipButton
											active
											behaviour="hover"
											children={translate('TICKET.EXPORT.DOWNLOADING')}
											buttonProps={{
												isDisabled: isExporting,
												icon: faDownload,
											}}
											tooltipProps={{
												placement: 'bottom',
												showArrow: true,
											}}
										/>
									) : (
										<UfinetButton icon={faDownload} isDisabled={isExporting} onClick={() => handleExportIncidents()} />
									))}
							</div>
						}
						afterTablePFSEvent={setTablePFSEvent}
						lazySettings={
							paging && {
								...paging,
								loading: loadingIncidents,
								onPage: (event: DataTablePFSEvent) => findTickets(event),
								onFilter: (event: DataTablePFSEvent) => findTickets(event),
								onSort: (event: DataTablePFSEvent) => findTickets(event),
							}
						}
					/>
				</UfinetSectionBox>
			</form>
		</UfinetBox>
	)
}

export { ListIncidentsPage }
